var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-table',{attrs:{"hover":"","items":_vm.services,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(show)",fn:function(row){return [(!row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"20"},on:{"click":row.toggleDetails}}):_vm._e(),(row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"MinusCircleIcon","size":"20"},on:{"click":row.toggleDetails}}):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('div',{staticClass:"shadow-xl rounded-lg border",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"d-flex border-bottom bg-white"},[_c('h4',{staticClass:"my-1 ml-1 p-0",staticStyle:{"min-width":"500px"}},[_vm._v("Features")]),_c('h4',{staticClass:"my-1",staticStyle:{"min-width":"200px"}},[_vm._v("Progress")]),_c('h4',{staticClass:"my-1 ml-2",staticStyle:{"min-width":"200px"}},[_vm._v("Team")]),_c('h4',{staticClass:"mt-1",staticStyle:{"min-width":"200px"}},[_vm._v("Completion Date")])]),_c('div',{staticStyle:{"background-color":"#fafafa"}},_vm._l((row.item.features),function(feature,index){return _c('div',{key:'feature_' + index,staticClass:"d-flex mt-1 border-bottom pb-1 ml-1"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"min-width":"500px"}},[_vm._v(_vm._s(feature.feature_name))]),_c('b-form-checkbox',{attrs:{"disabled":!feature.user_type_key || feature.user_type_key !== _vm.user_type,"checked":_vm.isFeatureCompleted(feature.feature_id)},on:{"change":function (val) {
                  if (val) {
                    _vm.markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id
                    );
                  } else {
                    _vm.markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id,
                      true
                    );
                  }
                }}}),_c('div',{staticStyle:{"min-width":"200px"}},[_c('b-badge',{staticClass:"ml-1 mr-5",attrs:{"variant":_vm.isFeatureCompleted(feature.feature_id)
                    ? 'success'
                    : 'warning'}},[_vm._v(_vm._s(_vm.isFeatureCompleted(feature.feature_id) ? "Complete" : "Pending"))])],1),_c('div',{staticStyle:{"min-width":"200px"}},[_c('b-badge',{staticClass:"rounded-pill",attrs:{"variant":"primary"}},[_vm._v(_vm._s(feature.user_type_name))])],1),(_vm.getCompletedFeatureDate(feature.feature_id))?_c('div',{staticClass:"rounded-lg shadow-sm",staticStyle:{"padding":"2px 8px"}},[_vm._v(" "+_vm._s(_vm.moment(_vm.getCompletedFeatureDate(feature.feature_id)).format( "DD MMM, YYYY HH:mm:ss" ))+" ")]):_vm._e()],1)}),0)])]}},{key:"cell(buy_date)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.moment(row.item.buy_date).format("DD MMM, YYYY - HH:mm:ss"))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }